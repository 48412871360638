/*
  Learn To Fight Alone :: Sudip
*/
.wqerror{
  color: red;
}
.wqsuccess {
  color: green;
}
.lession-body .tab-content>.tab-pane label.message-data p {
    color: #39393a;
    padding-left: 65px;
    font-size: 13px;
}

.btn_assign{
	margin-left: 10px !important;
}
.feedback-text{
	line-height: 4px;
}
.feedback-data{
	padding-left:4px;
}

