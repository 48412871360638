/*
  Learn To Fight Alone :: Sudip
*/
.Notfound {
  padding: 150px;
}
.input-range__track--active {
    background: #259345!important;
}
.input-range__slider {
    background: #259345!important;
    border: 1px solid #259345!important;
}
span.input-range__label-container {
    font-weight: bold;
    color: #193e05;
	font-size: 14px;
}