/*
  Learn To Fight Alone :: Sudip
*/
.wqerror{
  color: red !important;
}
.wqsuccess {
  color: green !important;
}
.wqred {
  background-color: lightcoral !important;
}
.btn.btn_message_send {
    background: #a1a94a;
    padding: 5px 26px;
    color: #fff;
    border-radius: 0px;
    border: none;
	    margin-top: 10px;
}
.btn.btn_message_send:hover,.btn.btn_message_send:focus{
	 background: #747936;
	 border: none;
}
span.result_message {
    margin: 5px 10px;
    color: #747936;
    vertical-align: -webkit-baseline-middle;
}