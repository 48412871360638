/*
  Learn To Fight Alone :: Sudip
*/
.wqerror{
  color: red;
}
.wqsuccess {
  color: lightgreen;
}
.wqnotexist_email {
  display: none;
}
.wqexist_email {
  display: none;
}
.wqexist_pass {
  display: none;
}
.without-succ {
	margin-left: 42.5% !important;
    margin-top: 1% !important;
}