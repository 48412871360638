/*
  Learn To Fight Alone :: Sudip
*/
.wqerror{
  color: red;
}
.wqsuccess {
  color: green;
}
.wqred {
  background-color: lightcoral;
}
.model{
	    padding-left: 0;
}
.btn-danger{	
	padding: 8px 21px;
	font-size: 15px;
	font-weight: 500;
	text-transform: uppercase;
	color: #fff !important;
	border: none !important;
	border-radius: 5px;
}
.btn-send{
	padding: 8px 21px;
	background:#A9B710;
	font-size: 15px;
	font-weight: 500;
	text-transform: uppercase;
	color: #fff !important;
	border: none !important;
	border-radius: 5px;
}
.btn-send:focus,.btn-send:hover{
	background:#98a50d;
	border: none !important;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
#messagemodel .modal-content {
	border-radius:0px;
	margin: 10% auto;
	}

	.message-header {
	background: #A9B710;
	border-radius: 0px;
	color: #fff;
	}
.message-box .form-group .form-control {
    border-radius: 0px;
    border: 1px solid #a9b710;
    width: 100%;
    font-size: 14px;
}
.message-box .form-group .form-control:focus {
    border: 1px solid #a9b710;
    box-shadow: 0 0 0 2px rgba(169, 183, 16, 0.4);
}
#messagemodel .modal-footer {
    border-top: 1px solid #a9b710;
	}
#messagemodel .message-box .form-group label{
	margin-bottom: -0.5rem;
}
.message-box .form-group label span.text-label{
    color:#717171;
    font-size: 14px;
	margin-right:3px;
}
.message-box .form-group label span.title-label{
    color: #808a0f;
    font-size: 14px;
	margin-right:3px;
}
.mylesson-details .fade:not(.show) {
    opacity: 1;
}