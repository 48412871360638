/*
  Learn To Fight Alone :: Sudip
*/
.Notfound {
  padding: 150px;
}
.wqerror{
  color: red;
}
.wqmessage{
  color: red;
}
.wqsuccess {
  color: green;
}
.wqnotexist_email {
  display: none;
}
.wqexist_email {
  display: none;
}
.wqexist_pass {
  display: none;
}
.message-box .form-group label span.title-label {
    color: #6c7507;
    font-size: 18px;
    margin-right: 0;
}
.btn-send {
    padding: 8px 21px;
    background: #A9B710;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff !important;
    border: none !important;
    border-radius: 0;
}
#messagemodel .modal-content {
    border-radius: 0px;
    margin: 37% auto;
}